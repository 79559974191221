import React from 'react'
import { useQuery } from '@apollo/react-hooks'
import { loader } from 'graphql.macro'
import { useParams } from 'react-router-dom'
import { Helmet } from 'react-helmet'
import { Loading } from '../utils'

import SubscriptionIssueNav from './SubscriptionIssueNav'

const subscriptionIssueQuery = loader('../queries/subscriptionIssue.graphql')

function SubscriptionIssue() {
  let { subscriptionId, issuePosition } = useParams()
  const { loading, error, data } = useQuery(
    subscriptionIssueQuery,
    {variables: { position: parseInt(issuePosition), subscriptionId }}
  )

  if (loading) return <Loading />
  if (error) return <p>Error...</p>

  const { subscriptionIssue: issue, subscription } = data
  if(!issue) {
    return (
        <div>
          Sorry, that issue was not found.
        </div>
    )
  }
  return (
    <div>
      <Helmet>
        <title>{`${issue.title} of "${subscription.book.title}"`}</title>
      </Helmet>
      <h1 className="text-2xl my-4">{`${issue.title} of "${subscription.book.title}"`}</h1>
      <SubscriptionIssueNav subscription={subscription} currentIssue={issue.position} />
      <div className="issue" dangerouslySetInnerHTML={{__html: issue.htmlContent}}></div>
      <SubscriptionIssueNav subscription={subscription} currentIssue={issue.position} />
    </div>
  )

}

export default SubscriptionIssue
