import React from 'react'
import { Redirect, useHistory, useLocation, Route } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSync } from '@fortawesome/free-solid-svg-icons'
import { REDIRECT_AFTER_LOGIN } from './constants'
import useCurrentUser from './useCurrentUser'
import { useApolloClient } from '@apollo/react-hooks'

export function sendMethodDisplay(sendMethod) {
  const displays = {
    email: <span><em>Email</em>: we will email chapters to you</span>,
    web: <span><em>Web</em>: read chapters in your browser on the serial.ly site</span>,
    kindle: <span><em>Kindle</em>: we will send chapters to your Kindle or the Kindle App (see below for instructions)</span>
  }
  return displays[sendMethod]
}

export function BackButton() {
  const history = useHistory()
  if (history.length > 1) {
    return <div><button href="#" onClick={() => history.goBack()}>&lt; Back</button></div>
  }
}

export function Loading() {
  return <FontAwesomeIcon icon={faSync} size="2x" spin className="mx-auto block h-64 text-orange-400" />
}

export function setFlash({cache, message, status = 'success'}) {
  cache.writeData({data: { flash: { message, status, __typename: 'FlashMessage'}}})
}

export function CurrentUserRequiredRoute({exact, path, component}) {
  const currentUser = useCurrentUser();
  const cache = useApolloClient();
  const location = useLocation();
  if (currentUser) {
    return <Route exact={exact} path={path} component={component} />
  }
  setFlash({cache, message: 'You must be logged in to view this page', status: 'success'})
  localStorage.setItem(REDIRECT_AFTER_LOGIN, location.pathname)
  return <Redirect to="/login" />
}

export function CurrentUserForbiddenRoute({exact, path, component}) {
  const currentUser = useCurrentUser();
  if (!currentUser) {
    return <Route exact={exact} path={path} component={component} />
  }
  return <Redirect to="/" />
}
