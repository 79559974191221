import React, { useState } from 'react'
import { useMutation } from '@apollo/react-hooks'
import { loader } from 'graphql.macro'
import classNames from 'classnames'
import { setFlash } from '../utils'

const createSubscriptionMutation = loader('../queries/createSubscription.graphql')
const accountInfo = loader('../queries/accountInfo.graphql')
const bookQuery = loader('../queries/book.graphql')

async function subscribe(bookSlug, createSubscription, setSubmitting) {
  await createSubscription({variables: { bookSlug: bookSlug }})
  setSubmitting(false)
}

function SubscribeButton({ bookSlug, className }) {
  const [isSubmitting, setSubmitting] = useState(false)
  const [createSubscription] = useMutation(createSubscriptionMutation, {
    refetchQueries: [{query: accountInfo}, {query: bookQuery, variables: { slug: bookSlug}}],
    update(cache, { data: { createSubscription: { errors } }}) {
      if (errors.length === 0) {
        setFlash({cache, message: 'Your subscription has been created'})
      }
    }
  })

  return (
    <button
      className={classNames(className, "btn btn-blue")}
      disabled={isSubmitting}
      onClick={() => subscribe(bookSlug, createSubscription, setSubmitting)}
    >
    Subscribe to this book
    </button>
  )
}

export default SubscribeButton
