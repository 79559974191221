import React from 'react'
import { useQuery } from '@apollo/react-hooks'
import { loader } from 'graphql.macro'
import SubscriptionsInfo from './SubscriptionsInfo'
import { Loading } from '../utils'

const subscriptionsQuery = loader('../queries/subscriptions.graphql')

function Subscriptions() {
  const { loading, error, data } = useQuery(subscriptionsQuery);

  if (loading) return <Loading />
  if (error) return <p>Error...</p>
  return (
    <SubscriptionsInfo subscriptions={data.subscriptions}/>
  )

}

export default Subscriptions
