import { useQuery } from '@apollo/react-hooks'
import { loader } from 'graphql.macro'

const currentUserQuery = loader('./queries/currentUserLocal.graphql')

function useCurrentUser() {
  const { data, loading, error } = useQuery(currentUserQuery)

  if (loading) { return 'loading' }
  if (error) { return 'error' }
  return data.currentUser
}

export default useCurrentUser;
