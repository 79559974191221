import React from 'react'
import { useQuery } from '@apollo/react-hooks'
import { loader } from 'graphql.macro'
import { useParams } from 'react-router-dom'
import BookListItem from './BookListItem'
import SubscriptionEditForm from './SubscriptionEditForm'
import SubscriptionButtons from './SubscriptionButtons'
import { BackButton } from '../utils'
import { Loading } from '../utils'

const subscriptionQuery = loader('../queries/subscription.graphql')

function SubscriptionEdit() {
  let { subscriptionId } = useParams()
  const { loading, error, data } = useQuery(
    subscriptionQuery,
    {variables: { id: subscriptionId }}
  );

  if (loading) return <Loading />
  if (error) return <p>Error...</p>

  const { subscription } = data
  if(!subscription) {
    return (
      <div>
        Sorry, that subscription was not found.
      </div>
    )
  }

  return (
    <div>
      <BackButton />
      <h1 className="header">Editing your subscription for "{subscription.book.title}"</h1>
      <BookListItem book={subscription.book} className="p-2 flex flex-row content-center" />
      <SubscriptionButtons subscription={subscription} showEdit={false} />
      <SubscriptionEditForm subscription={subscription} />
    </div>
  )
}

export default SubscriptionEdit
