import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSearch } from '@fortawesome/free-solid-svg-icons'
import { useHistory } from 'react-router-dom'
import { Formik, Field, Form } from 'formik'
import classNames from 'classnames'

function SearchBar({ inHeader }) {
  let history = useHistory()
  const formClassName = classNames(
    "w-full w-2/3 lg:w-5/6 md:mx-4 b-solid border rounded p-2 bg-gray-100 truncate",
    { "hidden md:inline": inHeader }
  )
  const fieldClassName = classNames(
    "w-2/3 md:mr-4 lg:w-5/6 bg-gray-100",
    { "hidden md:inline": inHeader }
  )

  return (
    <Formik
      initialValues={{search: ''}}
      onSubmit={({ search }) => {
        if (search === "" || search === null) { return }

        history.push(`/books?search=${search}`)
      }}
    >
      {props => {
        return (
          <Form className={formClassName}>
            <button className="mr-2"><FontAwesomeIcon icon={faSearch} /></button>
            <Field
              type="text"
              name="search"
              placeholder="Search for title or author"
              className={fieldClassName}
            />
          </Form>
        )}}
    </Formik>
  )

}

SearchBar.defaultProps = { inHeader: false }

export default SearchBar;
