import React from 'react'
import { Helmet } from 'react-helmet'
import { useParams, Link } from 'react-router-dom'
import { useQuery } from '@apollo/react-hooks'
import { loader } from 'graphql.macro'
import useCurrentUser from '../useCurrentUser'
import SubscribeButton from './SubscribeButton'
import { Loading } from '../utils'

const bookQuery = loader('../queries/book.graphql')

function subscriptionInfo(book, currentUser) {
  if (currentUser === null) {
    return <Link className="btn btn-blue mt-4" to="/login">Please login to subscribe to this book</Link>
  }

  const subscriptionState = book.subscription && book.subscription.state
  if (subscriptionState === 'active' || subscriptionState === 'paused') {
    return (
      <div className="mt-4">
        <p>You are reading this book!</p>
        <p><Link to={`/subscriptions/${book.subscription.id}`} className="btn btn-blue">subscription info</Link></p>
      </div>
    )
  }
  return (
    <SubscribeButton bookSlug={book.slug} className="mt-4" />
  )
}

function Book() {
  let { bookSlug } = useParams();
  const { loading, error, data } = useQuery(
    bookQuery,
    { variables: { slug: bookSlug } }
  )
  const currentUser = useCurrentUser()

  if (loading) return <Loading />
  if (error) return <p>Error...</p>

  const book = data.book
  return (
    <div className="container mx-auto p-4 text-center">
      <Helmet>
        <title>Serial.ly | {book.title}</title>
      </Helmet>
      <h1 className="text-4xl my-4">{book.title}</h1>
      <img
        src={book.heroCoverUrl}
        className="mx-auto h-64 md:h-auto"
        alt={`cover for ${book.title}`}/>
      <div className="text-xl mt-4">
        by <span>{book.authors[0].name}</span>
      </div>
      <div className="text-gray-600">{book.issuesCount} issues</div>
      { subscriptionInfo(book, currentUser) }
    </div>
  )
}

export default Book;
