import React, { useState } from 'react'
import { Formik, Field, Form, ErrorMessage } from 'formik'
import { useMutation } from '@apollo/react-hooks'
import { loader } from 'graphql.macro'
import * as Yup from 'yup'

const forgotPasswordMutation = loader('../queries/forgotPassword.graphql')

function resetForm(forgotPassword, setSubmitState, setEmail) {
    return (
    <div>
      <h1 className="header">Forgot your password?</h1>
      <p>Please enter your email below. We'll send you a message with instructions to reset your password.</p>
      <Formik
        initialValues={{email: ''}}
        validationSchema={Yup.object({
          email: Yup.string().email('Please enter a valid email address').required('An email is required'),
        })}
        onSubmit={async (values, { setErrors, setSubmitting }) => {
          setSubmitting(true)
          const { data: { forgotPassword: { errors, email }}} = await forgotPassword({variables: values})
          const fixedErrors = {}
          errors.forEach((f) => { fixedErrors[f.field] = f.message })
          setErrors(fixedErrors)
          setSubmitting(false)
          if (errors.length === 0) {
            setEmail(email)
            setSubmitState('submitted')
          }
        }}
      >
        {props => {
          const { isSubmitting } = props;
          return (
            <Form className="styled" disabled={isSubmitting}>
              <label
                htmlFor="email">
                Email
              </label>
              <Field
                name="email"
                type="email"
                placeholder="you@gmail.com"
              />
              <ErrorMessage name="email">{ msg => <span className="errorMessage">{msg}</span> }</ErrorMessage>
              <button
                className="mt-8 btn btn-blue"
                disabled={isSubmitting}
                type="submit">Request a password reset email</button>
            </Form>
          )}}
      </Formik>
    </div>
  )
}

function resetInstructions(email) {
  return (
    <>
      <h1 className="header">Email Sent</h1>
      <p>We just sent an email to {email}.</p>
      <p>Please follow the instructions in the email to reset your password.</p>
    </>
  )

}
function ForgotPasswordForm() {
  const [forgotPassword] = useMutation(forgotPasswordMutation)
  const [submitState, setSubmitState] = useState('showForm')
  const [email, setEmail] = useState(null)

  if (submitState === 'submitted') {
    return resetInstructions(email)
  } else {
    return resetForm(forgotPassword, setSubmitState, setEmail)
  }
}

export default ForgotPasswordForm
