import React, { useState } from 'react'
import { CSSTransitionGroup } from 'react-transition-group'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCaretRight, faCaretDown } from '@fortawesome/free-solid-svg-icons'
import { Link } from 'react-router-dom'
import DeliverIssueButton from './DeliverIssueButton'

function IssueDelivery({ delivery, subscription }) {
  const deliveredAt = new Date(delivery.deliveredAt);
  return (
    <>
      <dt key={`${delivery.id}-title`} className="font-bold mt-2">{delivery.title}</dt>
      <dd key={`${delivery.id}-date`}>First delivered on {deliveredAt.toLocaleDateString()}</dd>
      <dd key={`${delivery.id}-read-link`}><Link to={`/subscriptions/${subscription.id}/issues/${delivery.position}`} className="underline class-blue-600" target="_blank">read online</Link></dd>
      {subscription.sendMethod !== 'web' && <dd key={`${delivery.id}-resend`}><DeliverIssueButton subscriptionId={subscription.id} issuePosition={delivery.position} /></dd>}
    </>
  )
}

function SubscriptionHistory({ subscription }) {
  const [showHistory, setShowHistory] = useState(false)
  if (subscription.issueDeliveries.length === 0) {
    return <></>
  }

    return (
      <div className="mb-4">
        <p className="mb-0" onClick={() => setShowHistory(!showHistory)}><FontAwesomeIcon icon={showHistory ? faCaretDown : faCaretRight} /> history</p>
        <CSSTransitionGroup
          transitionName="animate-height"
          transitionEnterTimeout={300}
          transitionLeaveTimeout={300}>
            {showHistory &&
             <dl key={`issue-history-${subscription.id}`} className="pl-4">
                { subscription.issueDeliveries.map(delivery => {
                  return (
                    <IssueDelivery delivery={delivery} subscription={subscription} key={`delivery-${delivery.position}`}/>
                  )
                })}
              </dl>
            }
        </CSSTransitionGroup>
      </div>
    )
}

export default SubscriptionHistory;
