import React from 'react';
import { Helmet } from 'react-helmet'
import { Switch, Route } from 'react-router-dom'
import { useQuery } from '@apollo/react-hooks'
import { loader } from 'graphql.macro'
import Burger from './Burger'
import Header from './Header'
import AccountInfo from './AccountInfo'
import AccountInfoForm from './AccountInfoForm'
import BookList from './BookList'
import Book from './Book'
import SearchBar from './SearchBar'
import Subscription from './Subscription'
import Subscriptions from './Subscriptions'
import SubscriptionEdit from './SubscriptionEdit'
import SubscriptionIssue from './SubscriptionIssue'
import LoginForm from './LoginForm'
import SignUpForm from './SignUpForm'
import SignOut from './SignOut'
import ForgotPasswordForm from './ForgotPasswordForm'
import ResetPasswordForm from './ResetPasswordForm'
import FlashMessage from './FlashMessage'
import FaviconTester from './FaviconTester'
import { CurrentUserRequiredRoute, CurrentUserForbiddenRoute } from '../utils'
import { Loading } from '../utils'
const currentUserQuery = loader('../queries/currentUser.graphql')

function App() {
  const { loading, error } = useQuery(currentUserQuery)
  if (loading) return <Loading />
  if (error) return <p>Error...</p>

  return (
    <div className="font-serif">
      <Helmet>
        <title>Serial.ly</title>
      </Helmet>
      <Burger />
      <Header />
      <div className="m-4 pt-24 mx-auto px-12 md:px-16 md:w-5/6">
        <FlashMessage />
        <Switch>
          {/* public routes */}
          <Route exact path="/" component={BookList} />
          <Route exact path="/books" component={BookList} />
          <Route exact path="/books/:bookSlug" component={Book} />
          <Route exact path="/search" component={SearchBar} />
          <Route exact path="/sign_out" component={SignOut} />
          <Route exact path="/forgot_password" component={ForgotPasswordForm} />
          <Route exact path="/reset_password/:token" component={ResetPasswordForm}/>
          {/* private routes */}
          <CurrentUserRequiredRoute exact path="/account" component={AccountInfo} />
          <CurrentUserRequiredRoute exact path="/account/edit" component={AccountInfoForm} />
          <CurrentUserRequiredRoute exact path="/subscriptions" component={Subscriptions} />
          <CurrentUserRequiredRoute exact path="/subscriptions/:subscriptionId" component={Subscription} />
          <CurrentUserRequiredRoute exact path="/subscriptions/:subscriptionId/edit" component={SubscriptionEdit} />
          <CurrentUserRequiredRoute exact path="/subscriptions/:subscriptionId/issues/:issuePosition" component={SubscriptionIssue} />
          {/* not-logged-in routes */}
          <CurrentUserForbiddenRoute exact path="/login" component={LoginForm} />
          <CurrentUserForbiddenRoute exact path="/sign_up" component={SignUpForm} />
          { process.env.NODE_ENV === 'development' && <Route path="/favi" component={FaviconTester}/>}
        </Switch>
      </div>
    </div>
  );
}

export default App;
