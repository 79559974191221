import React from 'react'
import { Link } from 'react-router-dom'

function BookListItem({book, className}) {
  return (
    <li className={className}>
      <Link to={`/books/${book.slug}`}>
        <img src={book.listCoverUrl} alt={`cover page for '${book.title}'`} />
      </Link>
      <div className="ml-4 my-8">
        <div className="">
          <Link className="text-blue-600" to={`/books/${book.slug}`}>{book.title}</Link>
          { } by {book.authors[0].name}
        </div>
        <div className="text-gray-600">
          {book.issuesCount} issues
        </div>
      </div>
    </li>
  )
}

BookListItem.defaultProps = {
  className: "p-2 px-0 flex flex-row content-center border-gray-400 border-b last:border-none"
}

export default BookListItem;
