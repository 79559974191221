import React from 'react'
import { Link } from 'react-router-dom'
import { sendMethodDisplay } from '../utils'
import KindleInstructions from './KindleInstructions'

function UserInfo({ currentUser }) {
  return (
    <div>
      <h1 className="header">Your Account</h1>
      <dl className="styled">
        <dt>Email</dt>
        <dd>{currentUser.email}</dd>
      { currentUser.sendMethod === 'kindle' &&
        <>
          <dt>Kindle Email</dt>
          <dd>{currentUser.kindleEmail}</dd>
        </>
      }
        <dt>Password</dt>
        <dd>***************</dd>
        <dt>Send Method</dt>
        <dd>{ sendMethodDisplay(currentUser.sendMethod) }</dd>
      </dl>
      <Link to="/account/edit" className="btn btn-blue">Edit your account settings</Link>
      {currentUser.sendMethod === 'kindle' && <KindleInstructions onForm={false}/> }
    </div>
  )
}

export default UserInfo
