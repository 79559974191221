import React from 'react';
import { useEffect } from 'react'
import { useQuery, useApolloClient } from '@apollo/react-hooks'
import { loader } from 'graphql.macro'
import classNames from 'classnames'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimes } from '@fortawesome/free-solid-svg-icons'
import { Loading } from '../utils'

const getFlashQuery = loader('../queries/getFlash.graphql')

const clearFlash = (cache) => {
  cache.writeData({data: { flash: { message: null, status: null, __typename: 'FlashMessage'}}})
}

function FlashMessage() {
  const { loading, error, data } = useQuery(getFlashQuery)
  const cache = useApolloClient();
  const { flash: { message, status} } = data

  useEffect(() => {
    if (!message || !status) return

    // If you change this time, you need to set the time
    // on the animation-duration on the flash class in index.css
    setTimeout(() => clearFlash(cache), 5000)
  }, [cache, message, status])

  if (loading) { return <Loading /> }
  if (error) { return <p>Error...</p> }

  if (!message || !status) { return null }

  return (
    <div className="flash-container">
      <div className={classNames('flash', `flash-${status}`)}>
        <span className="flex-grow text-gray-700">{message}</span>
        <FontAwesomeIcon className="my-auto cursor-pointer" icon={faTimes} onClick={() => clearFlash(cache)}/>
      </div>
    </div>
  )
}

export default FlashMessage;
