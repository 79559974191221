import React from 'react'
import { Helmet } from 'react-helmet'
import { Formik, Field, Form, ErrorMessage } from 'formik'
import { useMutation } from '@apollo/react-hooks'
import { loader } from 'graphql.macro'
import { useHistory, Redirect, Link } from 'react-router-dom'
import * as Yup from 'yup'
import useCurrentUser from '../useCurrentUser'
import { setFlash } from '../utils'
import { Loading } from '../utils'

const createUserMutation = loader('../queries/createUser.graphql')

function SignUpForm() {
  let history = useHistory()
  const loggedIn = useCurrentUser()
  const [createUser] = useMutation(createUserMutation, {
    update(cache, { data: { createUser: { currentUser, errors } }}) {
      if (currentUser && errors.length === 0) {
        setFlash({cache, message: 'Thanks for signing up!'})
        cache.writeData({data: { currentUser }})
      }
    }
  })
  // Date.getTimezoneOffset() returns the number of minutes
  // you are from GMT, but is the negative of your actual timezone offset
  const timezoneOffset = new Date().getTimezoneOffset() /  - 60.0

  if (loggedIn === 'loading') return <Loading />
  if (loggedIn === 'error') return <p>Error...</p>
  if (loggedIn) return <Redirect to="/" />

  return (
    <div>
      <Helmet>
        <title>Serial.ly | Sign Up</title>
      </Helmet>
      <h1 className="header">Create Your Serial.ly Account</h1>
      <Formik
        initialValues={{email: '', password: '', timezoneOffset: timezoneOffset}}
        validationSchema={Yup.object({
          email: Yup.string().email('Please enter a valid email address').required('An email is required'),
          password: Yup.string().min(8, 'Your Password must be at least 8 characters long').required('A password is required')
        })}
        onSubmit={async (values, { setErrors, setSubmitting }) => {
          setSubmitting(true)
          const { data: { createUser: { currentUser, errors }}} = await createUser({variables: values})
          const fixedErrors = {}
          errors.forEach((f) => { fixedErrors[f.field] = f.message })
          setErrors(fixedErrors)
          setSubmitting(false)
          if (currentUser && errors.length === 0) {
            history.push('/')
          }
        }}
      >
        {props => {
          const { isSubmitting } = props;
          return (
            <Form className="styled" disabled={isSubmitting}>
              <label
                htmlFor="email">
                Email
              </label>
              <Field
                name="email"
                type="email"
                placeholder="you@gmail.com"
              />
              <ErrorMessage name="email">{ msg => <span className="errorMessage">{msg}</span> }</ErrorMessage>
              <label
                htmlFor="password">
                Password
              </label>
              <Field
                name="password"
                type="password"
              />
              <ErrorMessage name="password">{ msg => <span className="errorMessage">{msg}</span> }</ErrorMessage>
              <div className="mt-4 -mb-4">
                <Link className="text-gray-600 text-sm" to="/login">Login here</Link>
              </div>
              <button
                className="mt-8 btn btn-blue"
                disabled={isSubmitting}
                type="submit">Create User</button>
            </Form>
          )}}
      </Formik>
    </div>
  )
}

export default SignUpForm
