import gql from 'graphql-tag';

export const typeDefs = gql`

  type FlashMessage {
    message: String
    status: String
  }

  extend type Query {
    flash: FlashMessage
  }
`;

export const resolvers = {};
