import React from 'react'
import { Link } from 'react-router-dom'
import PauseSubscriptionButton from './PauseSubscriptionButton'
import ResumeSubscriptionButton from './ResumeSubscriptionButton'

function EditSubscriptionButton(subscription) {
  return <Link to={`/subscriptions/${subscription.id}/edit`} className="btn btn-blue">Edit</Link>
}

function SubscriptionButtons({ subscription, showEdit }) {
  const klasses = showEdit ? 'ml-4' : null
  switch (subscription.state) {
  case 'active':
    return (
      <>
        {showEdit && EditSubscriptionButton(subscription)}
        <PauseSubscriptionButton subscription={subscription} className={ klasses } />
      </>
    )
  case 'paused':
    return (
      <>
        {showEdit && EditSubscriptionButton(subscription)}
        <ResumeSubscriptionButton subscription={subscription} className={ klasses }/>
      </>
    )
  case 'completed':
    return <span>You have read all issues of this book!</span>
  case 'abandoned':
    return <span>You have cancelled this subscription</span>
  default:
    return null
  }
}

export default SubscriptionButtons
