import React from 'react'
import { useQuery } from '@apollo/react-hooks'
import { loader } from 'graphql.macro'
import UserInfo from './UserInfo'
import { Loading } from '../utils'

const accountInfoQuery = loader('../queries/accountInfo.graphql')

function AccountInfo() {
  const { loading, error, data } = useQuery(accountInfoQuery);

  if (loading) return <Loading />
  if (error) return <p>Error...</p>
  return (
    <div>
      <UserInfo currentUser={data.currentUser}/>
    </div>
  )
}

export default AccountInfo;
