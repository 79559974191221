import React from 'react';
import ReactDOM from 'react-dom';
import { ApolloProvider } from '@apollo/react-hooks'
import ApolloClient from 'apollo-boost'
import { InMemoryCache } from 'apollo-cache-inmemory'
import { BrowserRouter } from 'react-router-dom'
import './tailwind.css';
import App from './components/App';
import * as serviceWorker from './serviceWorker';
import { typeDefs, resolvers } from './resolvers'

const api_url = process.env.NODE_ENV === 'production' ? 'https://serially-staging.herokuapp.com/graphql' : 'http://api.serially.next/graphql'
const cache = new InMemoryCache()
const client = new ApolloClient({
  uri: api_url,
  cache,
  credentials: 'include',
  typeDefs,
  resolvers
});

cache.writeData({ data:
                  { flash: {message: null, status: null, __typename: 'FlashMessage'}}
                });

ReactDOM.render(
    <BrowserRouter>
      <ApolloProvider client={client}>
        <App />
      </ApolloProvider>
    </BrowserRouter>
  , document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
