import React from 'react'
import { Link } from 'react-router-dom'
import SearchBar from './SearchBar'
import TextLogo from './TextLogo'
import useCurrentUser from '../useCurrentUser'
import { Loading } from '../utils'

function loginLinks() {
  return (
    <>
      <Link to="/sign_up" className="mr-4">Sign up</Link>
      <Link to="/login" className="">Log in</Link>
    </>
  )
}

function userLinks() {
  return (
    <>
      <Link to="/account" className="mr-4">Account</Link>
      <Link to="/sign_out" className="">Log out</Link>
    </>
  )
}

function Header() {
  const loggedIn = useCurrentUser()
  if (loggedIn === 'loading') return <Loading />
  if (loggedIn === 'error') return <p>Error...</p>

  return (
    <div className="px-10 pb-8 md:h-24 md:pt-4 bg-orange-100 border-b-solid border-b fixed w-full top-0">
      <div className="flex flex-row flex-no-wrap justify-between h-8">
        <div className="sm:block md:hidden">
          <TextLogo />
        </div>
        <div className="hidden md:block justify-start md:h-16">
          <TextLogo />
          {/* The ml-1 makes the B in books line up exactly with the covers in the main div */}
          <Link to="/books" className="ml-1 mr-4">Books</Link>
          { loggedIn && <Link to="/subscriptions">Your Subscriptions</Link> }
        </div>
        <div className="justify-center items-end content-end h-16 flex-grow">
          <span className="text-4xl">&nbsp;</span>
          <SearchBar inHeader={true} />
        </div>
        <div className="justify-end hidden md:block items-end content-end h-16">
          <span className="text-4xl">&nbsp;</span>
          { loggedIn && userLinks() }
          { !loggedIn && loginLinks() }
        </div>
      </div>
    </div>
  )
}

export default Header;
