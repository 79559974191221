import React from 'react'
import { Link } from 'react-router-dom'
import SubscriptionInfo from './SubscriptionInfo'

function SubscriptionsInfo({ subscriptions }) {
  if (subscriptions.length === 0) {
    return (
      <div className="mt-8">
        <span>You don't have any subscriptions yet! You can find a book to subscribe to on the <Link className="text-blue-600" to="/books">book list</Link>.</span>
      </div>
    )
  }
  return (
    <div className="mt-8">
      <h1 className="header">Your Subscriptions</h1>
      { subscriptions.map((s) => <SubscriptionInfo subscription={s} key={`subscription-${s.id}`} /> )}
    </div>
  )
}

export default SubscriptionsInfo
