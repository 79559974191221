import React from 'react'
import { Link } from 'react-router-dom'

function FaviconTester() {
  return (
    <div className="px-4 pb-8 md:h-24 md:pt-4 bg-orange-100 border-b-solid border-b">
      <div className="h-8">
        <Link className="flex items-center justify-center h-12 w-12 text-4xl relative border border-solid border-2 border-orange-600 rounded-full" style={{bottom: "-0.25rem"}} to="/">
          <span className="text-orange-600">S</span>
        </Link>
      </div>
    </div>
  )
}
export default FaviconTester
