import React from 'react'
import { useMutation } from '@apollo/react-hooks'
import { loader } from 'graphql.macro'
import { Formik, Field, Form, ErrorMessage } from 'formik'
import { useHistory } from 'react-router-dom'
import * as Yup from 'yup'
import { setFlash } from '../utils'

const updateSubscriptionMutation = loader('../queries/updateSubscription.graphql')

function SubscriptionEditForm({ subscription }) {
  let history = useHistory()
  const [updateSubscription] = useMutation(updateSubscriptionMutation, {
    update(cache, { data: { updateSubscription: { subscription, errors } }}) {
      if (errors.length === 0) {
        setFlash({cache, message: 'Your subscription has been updated'})
      }
    }
  })

  return (
    <Formik
      initialValues={{
        subscriptionId: subscription.id,
        frequency: subscription.frequency,
        nextIssueSendAt: subscription.nextIssueSendAtForForm
      }}
      validationScheme={Yup.object({
        frequency: Yup.number().required().min(1).max(60).integer('Please enter a number between 1 and 60')
      })}
      onSubmit={async (values, { setErrors, setSubmitting }) => {
        setSubmitting(true)
        values.frequency = parseInt(values.frequency)
        if (values.nextIssueSendAt === null) {
          delete values.nextIssueSendAt
        }
        const { data: { updateSubscription: { errors }}} = await updateSubscription({variables: values})
        const fixedErrors = {}
        errors.forEach((f) => { fixedErrors[f.field] = f.message })
        setErrors(fixedErrors)
        setSubmitting(false)
        if (errors.length === 0) {
          if (history.length > 1) {
            history.goBack()
          } else {
            history.push('/subscriptions')
          }
        }
      }}
      enableReinitialize={true}
    >
      {props => {
        const { isSubmitting } = props
        return (
          <Form className="styled" disabled={isSubmitting}>
            <label
              htmlFor="frequency">
              Frequency (in days)
            </label>
            <Field
              name="frequency"
              type="input"
            />
            <ErrorMessage name="frequency">{ msg => <span className="errorMessage">{msg}</span>}</ErrorMessage>

            {subscription.state === 'active' &&
             <>
              <label
                htmlFor="nextIssueSendAt">
                Next issue
              </label>
              <Field
                name="nextIssueSendAt"
                type="date"
              />
              <ErrorMessage name="nextIssueSendAt">{ msg => <span className="errorMessage">{msg}</span>}</ErrorMessage>
             </>
            }
            <button
              className="mt-8 btn btn-blue"
              disabled={isSubmitting}
              type="submit">
              Update Subscription
            </button>
          </Form>
        )
      }}
    </Formik>
  )
}

export default SubscriptionEditForm
