import React from 'react'
import { loader } from 'graphql.macro'
import { useQuery } from '@apollo/react-hooks'
import { useParams } from 'react-router-dom'
import SubscriptionInfo from './SubscriptionInfo'
import { Loading } from '../utils'

const subscriptionQuery = loader('../queries/subscription.graphql')

function Subscription() {
  let { subscriptionId } = useParams();
  const { loading, error, data } = useQuery(
    subscriptionQuery,
    { variables: { id: subscriptionId }}
  )
  if (loading) return <Loading />
  if (error) return <p>Error...</p>
  return (
    <SubscriptionInfo subscription={data.subscription}/>
  )
}

export default Subscription
