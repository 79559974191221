import React, { useState } from 'react'
import { loader } from 'graphql.macro'
import { useMutation } from '@apollo/react-hooks'
import { setFlash } from '../utils'

const deliverNextIssueMutation = loader('../queries/deliverNextIssue.graphql')

async function deliverNextIssue(subscription, deliverNextIssueM, setSubmitting) {
  setSubmitting('yes')
  await deliverNextIssueM({variables: { subscriptionId: subscription.id }})
  setSubmitting('done')
}

function buttonText(isSubmitting, defaultText) {
  switch(isSubmitting) {
  case 'no':
    return defaultText
  case 'yes':
    return 'delivering...'
  case 'done':
    return 'delivered!'
  default:
    return defaultText
  }
}

function DeliverNextIssueButton({subscription, defaultText = 'Deliver Next Issue'}) {
  const [submitState, setSubmitting] = useState('no')
  const [deliverNextIssueM] = useMutation(deliverNextIssueMutation, {
    update(cache, { data: { deliverNextIssue: { errors }}}) {
      if (errors.length === 0) {
        setFlash({cache, message: 'Your next issue has been sent!'})
      }
    }
  })

  if (subscription.state !== 'active') {
    return null;
  }

  return <button className="btn btn-blue btn-short mt-1" onClick={() => deliverNextIssue(subscription, deliverNextIssueM, setSubmitting)} disabled={submitState !== 'no'}>{buttonText(submitState, defaultText)}</button>
}

export default DeliverNextIssueButton
