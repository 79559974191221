import React, { useState } from 'react'
import { loader } from 'graphql.macro'
import { useMutation } from '@apollo/react-hooks'
import { setFlash } from '../utils'

const deliverIssueMutation = loader('../queries/deliverIssue.graphql')

async function deliverIssue(subscriptionId, issuePosition, deliverIssueM, setSubmitting) {
  setSubmitting('yes')
  await deliverIssueM({variables: { subscriptionId, issuePosition}})
  setSubmitting('done')
}

function buttonText(isSubmitting, defaultText) {
  switch(isSubmitting) {
  case 'no':
    return defaultText
  case 'yes':
    return 'delivering...'
  case 'done':
    return 'delivered!'
  default:
    return defaultText
  }
}

function DeliverIssueButton({subscriptionId, issuePosition, defaultText = 'Resend Issue'}) {
  const [submitState, setSubmitting] = useState('no')
  const [deliverIssueM] = useMutation(deliverIssueMutation, {
    update(cache, { data: { deliverIssue: { errors }}}) {
      if (errors.length === 0) {

        setFlash({cache, message: 'Issue re-sent'})
      }
    }
  })
  return <button className="btn btn-blue btn-short mt-1" onClick={() => deliverIssue(subscriptionId, issuePosition, deliverIssueM, setSubmitting)} disabled={submitState !== 'no'}>{buttonText(submitState, defaultText)}</button>
}

export default DeliverIssueButton
