import React, { useState } from 'react'
import { Formik, Field, Form, ErrorMessage } from 'formik'
import { useMutation } from '@apollo/react-hooks'
import { useHistory, useParams, Link } from 'react-router-dom'
import { loader } from 'graphql.macro'
import * as Yup from 'yup'
import { setFlash } from '../utils'

const resetPasswordMutation = loader('../queries/resetPassword.graphql')

function ResetPasswordForm() {
  const [showError, setShowError] = useState(false)
  const [resetPassword] = useMutation(resetPasswordMutation, {
    update(cache, { data: { resetPassword: { currentUser, errors }}}) {
      if (currentUser && errors.length === 0) {
        setFlash({cache, message: 'Your password has been updated'})
        cache.writeData({data: { currentUser }})
      }
    }
  })
  let history = useHistory()
  let { token } = useParams();

  return (
    <div>
      <h1 className="header">Reset your password?</h1>
      <p>Please enter your new password below.</p>
      <Formik
        initialValues={{password: '', token: token}}
        validationSchema={Yup.object({
          password: Yup.string().min(8, 'Your Password must be at least 8 characters long').required('A password is required')
        })}
        onSubmit={async (values, { setErrors, setSubmitting }) => {
          setSubmitting(true)
          const { data: { resetPassword: { currentUser, errors }}} = await resetPassword({variables: values})
          setSubmitting(false)
          if (currentUser && errors.length === 0) {
            history.push('/')
          } else {
            // The errors we get back from Rails and Devise are not useful,
            // so just turn on the display of a preset error message.
            setShowError(true)
          }
        }}
      >
        {props => {
          const { isSubmitting } = props;
          return (
            <Form className="styled" disabled={isSubmitting}>
              <label
                htmlFor="password">
                New Password
              </label>
              <Field
                name="password"
                type="password"
              />
              <ErrorMessage name="password">{ msg => <span className="errorMessage">{msg}</span> }</ErrorMessage>
              {showError &&
               <div className="errorMessage -mb-4">
                 <p>There was an error while updating your password. This can happen if the email to reset your password was sent over a few hours ago.</p>
                 <p>You can try again, and if that does not work, you might need to <Link to="/forgot_password" className="underline">ask for another forgot password email</Link>.</p>
               </div>
              }
              <button
                className="mt-8 btn btn-blue"
                disabled={isSubmitting}
                type="submit">Change your password</button>
            </Form>
          )}}
      </Formik>
    </div>
  )
}

export default ResetPasswordForm
