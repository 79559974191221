import React, { useState } from 'react'
import { loader } from 'graphql.macro'
import { useMutation } from '@apollo/react-hooks'
import  classNames from 'classnames'
import { setFlash } from '../utils'

const updateSubscriptionMutation = loader('../queries/updateSubscription.graphql')

async function pauseSubscription(subscription, updateSubscription, setSubmitting) {
  await updateSubscription({variables: { subscriptionId: subscription.id, state: 'paused'}})
  setSubmitting(false)
}

function PauseSubscriptionButton({ subscription, className }) {
  const klasses = classNames(className, "btn btn-blue")
  const [isSubmitting, setSubmitting] = useState(false)
  const [updateSubscription] = useMutation(updateSubscriptionMutation, {
    update(cache, { data: { updateSubscription: { errors } }}) {
      if (errors.length === 0) {
        setFlash({cache, message: 'Your subscription has been paused'})
      }
    }
  })
  return <button className={ klasses } onClick={() => pauseSubscription(subscription, updateSubscription, setSubmitting)} disabled={isSubmitting}>Pause</button>
}

export default PauseSubscriptionButton
