import React from 'react'

function KindleInstructions(props) {
  return (
    <div class="hint leading-4 mt-8">
      <h2 class="minor-header">Setting up "Send to Kindle"</h2>
      <p>You will need to do two things. First, you need to find your "Send-to-Kindle" email address. This <a href="https://www.amazon.com/gp/sendtokindle/email">document</a> from Amazon will tell you how to find it.</p>
      {props.onForm && <p>Once you have your "Send-to-Kindle" email address, enter it in the form above and click "Update Account".</p>}
    {!props.onForm && <p>Once you have your "Send-to-Kindle" email address, click on "Edit your account settings" and enter it.</p>}
      <p>Second, you will need to add "ebooks@serial.ly" to your "Approved Personal Document E-mail List". You can do this <a href="https://www.amazon.com/hz/mycd/myx?ref_=nav_AccountFlyout_myk#/home/settings/pdoc">here</a>.</p>
    </div>
  )
}

export default KindleInstructions
