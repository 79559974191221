import React from 'react'
import { Link } from 'react-router-dom'

function SubscriptionIssueNav({subscription, currentIssue}) {
  const prevPos = currentIssue - 1
  const nextPos = currentIssue + 1
  return (
    <div className="issue-nav">
      {prevPos > 0 && <Link to={`/subscriptions/${subscription.id}/issues/${prevPos}`}>&lt; prev</Link>}
      &nbsp;|&nbsp;
    {nextPos <= subscription.lastIssueNumber && <Link to={`/subscriptions/${subscription.id}/issues/${nextPos}`}>next &gt;</Link>}
    </div>
  )
}

export default SubscriptionIssueNav
