import React from 'react'
import { useMutation } from '@apollo/react-hooks'
import { loader } from 'graphql.macro'
import { Redirect } from 'react-router-dom'
import { setFlash } from '../utils'

const signOutMutation = loader('../queries/signOut.graphql')

function SignOut() {
  const [signOutUser, { called, loading, error }] =
        useMutation(signOutMutation,
                    {
                      update(cache, result) {
                        if(result.data.signOut.errors.length === 0) {
                          setFlash({cache, message: 'You have been logged out'})
                          cache.writeData({data: { currentUser: null}})
                        }
                      }
                    }
                   )

  if (!called) {
    signOutUser()
    return (<div>Calling...</div>)
  }

  if (loading) {
    return ( <div>Logging out...</div>)
  }

  if (error) {
    return ( <div>Error while logging out!</div>)
  }

  return (<Redirect to="/login" />)
}

export default SignOut
