import React from 'react'
import { Helmet } from 'react-helmet'
import { Formik, Field, Form, ErrorMessage } from 'formik'
import { useMutation } from '@apollo/react-hooks'
import { loader } from 'graphql.macro'
import { useHistory, Redirect, Link } from 'react-router-dom'
import useCurrentUser from '../useCurrentUser'
import { REDIRECT_AFTER_LOGIN } from '../constants'
import { setFlash } from '../utils'
import { Loading } from '../utils'

const signInMutation = loader('../queries/signIn.graphql')

function LoginForm() {
  let history = useHistory()
  const loggedIn = useCurrentUser()
  const [loginUser] = useMutation(signInMutation, {
    update(cache, { data: { signIn: { currentUser, errors } }}) {
      if (currentUser && errors.length === 0) {
        setFlash({cache, message: 'you are now logged in'})
        cache.writeData({data: { currentUser }})
      }
    }
  })

  if (loggedIn === 'loading') return <Loading />
  if (loggedIn === 'error') return <p>Error...</p>
  if (loggedIn) return <Redirect to="/" />
  if (loggedIn) {
    let redirect = localStorage.getItem(REDIRECT_AFTER_LOGIN);
    localStorage.removeItem(REDIRECT_AFTER_LOGIN);
    if (redirect === null) {
      redirect = "/"
    }
    return <Redirect to="/account" />

  }

  return (
    <div>
      <Helmet>
        <title>Serial.ly | Log In</title>
      </Helmet>
      <h1 className="header">Log in to Serial.ly</h1>
      <Formik
        initialValues={{email: '', password: ''}}
        onSubmit={async (values, { setErrors, setSubmitting }) => {
          setSubmitting(true)
          const { data: { signIn: { currentUser, errors }}} = await loginUser({variables: values})
          const fixedErrors = {}
          errors.forEach((f) => { fixedErrors[f.field] = f.message })
          setErrors(fixedErrors)
          setSubmitting(false)
          if (currentUser && errors.length === 0) {
            let redirect = localStorage.getItem(REDIRECT_AFTER_LOGIN);
            localStorage.removeItem(REDIRECT_AFTER_LOGIN);
            if (redirect === null) {
              redirect = '/'
            }
            history.push(redirect)
          }
        }}
      >
        {props => {
          const { isSubmitting } = props;
          return (
            <Form className="styled" disabled={isSubmitting}>
              <label
                htmlFor="email">
                Email
              </label>
              <Field
                name="email"
                type="email"
                placeholder="you@gmail.com"
              />
              <ErrorMessage name="email">{ msg => <span className="errorMessage">{msg}</span> }</ErrorMessage>
              <label
                htmlFor="password">
                Password
              </label>
              <Field
                name="password"
                type="password"
              />
              <ErrorMessage name="password">{ msg => <span className="errorMessage">{msg}</span> }</ErrorMessage>
              <div className="mt-6 -mb-4">
                <Link className="text-gray-600 text-sm last:ml-2" to="/sign_up">Create an account</Link>
                <Link className="text-gray-600 text-sm last:ml-2" to="/forgot_password">Forgot your password?</Link>
              </div>
              <button
                className="mt-8 btn btn-blue"
                disabled={isSubmitting}
                type="submit">Log in!</button>
            </Form>
          )}}
      </Formik>
    </div>
  )
}

export default LoginForm
