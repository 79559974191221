import React from 'react'
import BookListItem from './BookListItem'
import SubscriptionButtons from './SubscriptionButtons'
import SubscriptionHistory from './SubscriptionHistory'
import DeliverNextIssueButton from './DeliverNextIssueButton'

function SubscriptionInfo({ subscription }) {
  const lastIssueSentAt = subscription.lastIssueSentAt ? new Date(subscription.lastIssueSentAt) : null
  const lastIssueSentString = lastIssueSentAt && subscription.lastIssueSent ? `Issue ${subscription.lastIssueSent.position} sent on ${lastIssueSentAt.toLocaleDateString()}`
        : 'No issues sent yet'

  const nextIssueSendAt = subscription.nextIssueSendAt && subscription.nextIssueNumber ? new Date(subscription.nextIssueSendAt) : null
  const nextIssueSendString = nextIssueSendAt ? `Issue ${subscription.nextIssueNumber} will be sent on ${nextIssueSendAt.toLocaleDateString()}`
        : 'No issues scheduled'
  return (
    <div key={subscription.id} className="border-gray-400 border-b last:border-none pb-8">
      <dl className="styled">
        <BookListItem book={subscription.book} className="p-2 flex flex-row content-center"/>
        <dt>Last Issue Sent</dt>
        <dd>{lastIssueSentString}</dd>
        <dt>Issue Frequency</dt>
        <dd>Every {subscription.frequency} days</dd>
        <dt>Next Issue</dt>
        <dd>{nextIssueSendString}</dd>
        <dd><DeliverNextIssueButton subscription={subscription} /></dd>
      </dl>
      <SubscriptionHistory subscription={subscription}/>
      <SubscriptionButtons subscription={subscription} showEdit={true} />
    </div>
  )
}

export default SubscriptionInfo
