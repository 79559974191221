import React from 'react'
import { Link } from 'react-router-dom'

function TextLogo() {
  return (
    <Link className="mr-4 text-4xl relative md:ml-0 ml-4" style={{bottom: "-0.25rem"}} to="/">
      <span className="text-orange-600">S</span>erial.ly
    </Link>
  )
}

export default TextLogo;
