import React from 'react'
import { useQuery } from '@apollo/react-hooks'
import useQueryString from '../useQueryString'
import BookListItem from './BookListItem'
import { loader } from 'graphql.macro';
import { Loading } from '../utils'

const bookListQuery = loader('../queries/bookList.graphql')

function BookList() {
  const query = useQueryString()
  const search = query.get('search')
  const { loading, error, data } = useQuery(
    bookListQuery,
    { variables: { search: search } }
  )

  if (loading) return <Loading />
  if (error) return <p>Error...</p>
  if (data.bookSearch.length === 0) {
    return (
      <div>
        <p>Sorry, we could not find any books that matched that search!</p>
      </div>
    )
  }

  return (
    <ul className="container">
      {data.bookSearch.map((book) => <BookListItem book={book} key={book.slug} />)}
    </ul>
  )
}

export default BookList;
