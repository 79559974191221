import React from 'react'

import { Formik, Field, Form, ErrorMessage } from 'formik'
import { useMutation } from '@apollo/react-hooks'
import { loader } from 'graphql.macro'
import { useHistory } from 'react-router-dom'
import * as Yup from 'yup'
import useCurrentUser from '../useCurrentUser'
import { sendMethodDisplay, setFlash } from '../utils'
import { CSSTransitionGroup } from 'react-transition-group'
import KindleInstructions from './KindleInstructions'

const updateUserMutation = loader('../queries/updateUser.graphql')

function AccountInfoForm() {
  let history = useHistory()
  const currentUser = useCurrentUser()
  const [updateUser] = useMutation(updateUserMutation, {
    update(cache, { data: { updateUser: { currentUser, errors } }}) {
      if (currentUser && errors.length === 0) {
        setFlash({cache, message: 'Your account has been updated'})
        cache.writeData({data: { currentUser }})
      }
    }
  })

  return (
    <div>
      <h1 className="header">Update Your Account</h1>
      <Formik
        initialValues={{email: currentUser.email,
                        kindleEmail: currentUser.kindleEmail,
                        sendMethod: currentUser.sendMethod,
                        password: ''}}
        validationSchema={Yup.object({
          email: Yup.string().email('Please enter a valid email address').required('An email is required'),
          kindleEmail: Yup.string().email('Please enter the email address for your Kindle. It will look like "you@kindle.com". For instructions on how to find it, see below.'),
          password: Yup.string().min(8, 'Your Password must be at least 8 characters long')
        })}
        onSubmit={async (values, { setErrors, setSubmitting }) => {
          setSubmitting(true)
          const { data: { updateUser: { currentUser, errors }}} = await updateUser({variables: values})
          const fixedErrors = {}
          errors.forEach((f) => { fixedErrors[f.field] = f.message })
          setErrors(fixedErrors)
          setSubmitting(false)
          if (currentUser && errors.length === 0) {
            history.push('/account')
          }
        }}
      >
        {props => {
          const { isSubmitting, values } = props;
          return (
            <>
            <Form className="styled" disabled={isSubmitting}>
              <label
                htmlFor="email">
                Email
              </label>
              <Field
                name="email"
                type="email"
                placeholder="you@gmail.com"
              />
              <ErrorMessage name="email">{ msg => <span className="errorMessage">{msg}</span> }</ErrorMessage>
                <br />
                <p>Send Method (this sets how we deliver your chapters to you)</p>
              <fieldset className="radio-group">
                <Field id="sendMethodEmail" type="radio" name="sendMethod" value="email" />
                <label htmlFor="sendMethodEmail">{ sendMethodDisplay('email') }</label>
                <br />
                <Field id="sendMethodWeb" type="radio" name="sendMethod" value="web" />
                <label htmlFor="sendMethodWeb">{ sendMethodDisplay('web') }</label>
                <br />
                <Field id="sendMethodKindle" type="radio" name="sendMethod" value="kindle" />
                <label htmlFor="sendMethodKindle">{ sendMethodDisplay('kindle') }</label>
                <br />
              </fieldset>
              <CSSTransitionGroup
                transitionName="animate-height"
                transitionEnterTimeout={300}
                transitionLeaveTimeout={300}>
              { values.sendMethod === 'kindle' &&
                <fieldset key="kindleEmailTransitionGroup">
                 <label
                   htmlFor="kindleEmail">
                   Kindle Email
                 </label>
                 <Field
                   name="kindleEmail"
                   type="email"
                   placeholder="you@kindle.com"
                 />
                 <ErrorMessage name="kindleEmail">{ msg => <span className="errorMessage">{msg}</span> }</ErrorMessage>
               </fieldset>
              }
              </CSSTransitionGroup>
              <fieldset>
                <label
                  htmlFor="password">
                  Password
                </label>
                <Field
                  name="password"
                  type="password"
                />
                <ErrorMessage name="password">{ msg => <span className="errorMessage">{msg}</span> }</ErrorMessage>
              </fieldset>
              <button
                className="mt-8 btn btn-blue"
                disabled={isSubmitting}
                type="submit">Update Account</button>
            </Form>
            { values.sendMethod === "kindle" && <KindleInstructions onForm={true} />}
            </>
          )}}
      </Formik>
    </div>
  )
}

export default AccountInfoForm
