import React, { useState } from 'react';
import { Link } from 'react-router-dom'
import { slide as Menu } from 'react-burger-menu'
import useCurrentUser from '../useCurrentUser'
import { Loading } from '../utils'

function Burger() {
  const currentUser = useCurrentUser()
  const [isOpen, setOpen] = useState(false)
  if (currentUser === 'loading') return <Loading />
  if (currentUser === 'error') return <p>Error...</p>

  const MenuLink = ({to, children}) => {
    if (window.location.pathname === to) {
      return <span className="block text-white">{children}</span>
    } else {
      return <Link to={to} className="block" onClick={() => {setOpen(false)}}>{children}</Link>
    }
  }

  return (
    <Menu
      disableAutoFocus
      onStateChange={(state) => setOpen(state.isOpen)}
      isOpen={isOpen}>
      <MenuLink to="/">Home</MenuLink>
      <MenuLink to="/books">Books</MenuLink>
      <MenuLink to="/subscriptions">Your Subscriptions</MenuLink>
      <MenuLink to="/search">Search</MenuLink>

      { currentUser &&
        <>
          <MenuLink to="/account">Account</MenuLink>
          <MenuLink to="/sign_out">Log out</MenuLink>
        </>
      }
      { !currentUser &&
        <>
          <MenuLink to="/sign_up">Sign up</MenuLink>
          <MenuLink to="/login">Log in</MenuLink>
        </>
      }
    </Menu>
  )
}

export default Burger
